import gql from "graphql-tag";
import { ConfigData } from "../../config/config-context.js";
import {
    CommonContent,
    NewsFeedPageContent,
    NewsPageContent,
    ProductPageContent,
    ProductsPageContent,
    ProductSupportPageContent,
    SupportPageContent,
} from "../../i18n/content.js";
import { MediaItemPreview } from "./gallery.js";

export interface GetCommonContentData {
    common: {
        id: string;
        content: CommonContent;
        mediaItems: (MediaItemPreview | null)[] | null;
    };
    productsPage: {
        id: string;
        content: ProductsPageContent;
    };
    productPage: {
        id: string;
        content: ProductPageContent;
    };
    newsFeedPage: {
        id: string;
        content: NewsFeedPageContent;
    };
    newsPage: {
        id: string;
        content: NewsPageContent;
    };

    supportPage: {
        id: string;
        content: SupportPageContent;
    };
    productSupportPage: {
        id: string;
        content: ProductSupportPageContent;
    };
    app: {
        config: Partial<ConfigData> | null;
    } | null;
}

export const GET_COMMON_CONTENT = gql`
    query getCommonContent {
        app {
            id
            config
        }
        common: staticContent(id: "vt-site-common") {
            id
            content
            mediaItems {
                id
                content {
                    name
                    sourceUrl
                    ... on ImageContent {
                        thumbnails {
                            fileName
                            width
                            height
                        }
                    }
                }
            }
        }
        productsPage: staticContent(id: "vt-site-products-list") {
            id
            content
        }
        productPage: staticContent(id: "vt-site-product") {
            id
            content
        }
        newsFeedPage: staticContent(id: "vt-site-news-feed") {
            id
            content
        }
        newsPage: staticContent(id: "vt-site-news") {
            id
            content
        }
        supportPage: staticContent(id: "vt-site-support") {
            id
            content
        }
        productSupportPage: staticContent(id: "vt-site-product-support") {
            id
            content
        }
    }
`;
