import { SocialMediaLinkProps } from "../components/SocialMediaLink.js";
export const ASSETS_PATH = "/assets";

export const COPYRIGHT_LINK = "https://vitotechnology.com";

export const MAILTO_ADDRESS = "mailto:support@vitotechnology.com";

export const OPEN_GRAPH_IMAGE_PATH = `${ASSETS_PATH}/images/preview.jpg`;

export const OPEN_GRAPH_HOME_IMAGE_PATH = `${ASSETS_PATH}/images/main_preview.jpg`;

export const TRUSTPILOT_BADGE = `https://vitotechnology.com/assets/trustpilot_badges/en/trustpilot.svg`;
export const TRUSTPILOT_PAGE_URL = `https://www.trustpilot.com/review/vitotechnology.com`;

export const SLIDER_ASPECT_RATIO = "16:9";

export const SOCIAL_MEDIA_LINKS: SocialMediaLinkProps[] = [
    {
        iconKey: "facebook",
        link: "https://www.facebook.com/StarWalkApp",
    },
    {
        iconKey: "instagram",
        link: "https://www.instagram.com/starwalkapp/",
    },
    {
        iconKey: "twitter",
        link: "https://twitter.com/starwalk",
    },
    {
        iconKey: "medium",
        link: "https://starwalk.medium.com/",
    },
    {
        iconKey: "youtube",
        link: "https://www.youtube.com/user/VITOiPhone/featured",
    },
    // {
    //     iconKey: "pinterest",
    //     link: "https://www.pinterest.ru/starwalk_app/",
    // },
    // {
    //     iconKey: "tumblr",
    //     link: "http://starwalkapp.tumblr.com/",
    // },
];
