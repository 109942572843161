import { useQuery } from "@apollo/client/react/hooks/useQuery.js";
import React, { useContext } from "react";
import { Link } from "react-router-dom";

import { ANGLE_LEFT } from "../../config/icons.js";
import {
    GetNewsData,
    GetNewsVariables,
    GET_NEWS,
} from "../../graphql/queries/news.js";
import { findClosestThumbnail, formatDate } from "../../helpers/utils.js";
import Content from "../../i18n/content.js";
import MarkdownRenderer from "../common/MarkdownRenderer.js";
import ErrorMsg from "../common/errors/ErrorMsg.js";
import MetaTags from "../MetaTags.js";
import { container } from "../styles/common.css.js";
import Spinner from "../Spinner.js";
import Icon from "../svg/Icon.js";
import HtmlGoogleStructuredData from "../helmet/HtmlGoogleStructuredData.js";
import { DOMAIN_URL } from "../../config/env.js";
import { Thumbnail } from "../../graphql/queries/gallery.js";
import ImageItem from "../common/ImageItem.js";
import ShareButtons from "../common/ShareButtons.js";
import { COPYRIGHT_LINK } from "../../config/content.js";
import {
    angle,
    breadCrumbs,
    credit,
    creditLink,
    imageСaption,
    markdown,
    newsAuthor,
    newsDate,
    newsTitle,
} from "./News.css.js";
import NotFoundErrorMsg from "../common/errors/NotFoundErrorMsg.js";

interface Props {
    newsId: string;
    path: string;
}
const News: React.FC<Props> = ({ newsId, path }) => {
    const {
        newsPage: { backToNewsFeed, imageCredit, textCredit },
    } = useContext(Content);

    const { loading, error, data } = useQuery<GetNewsData, GetNewsVariables>(
        GET_NEWS,
        {
            variables: {
                id: newsId,
            },
        },
    );

    if (error) {
        return <ErrorMsg error={error} />;
    }

    if (loading) {
        return <Spinner />;
    }

    if (data && data.companyNews) {
        const news = data.companyNews;
        const content = news.content;
        const mediaItemContent = news.image.content;

        const ogImageThumbnail: Thumbnail = findClosestThumbnail(
            mediaItemContent.thumbnails,
        );

        const ogImageUrl =
            mediaItemContent.sourceUrl + ogImageThumbnail.fileName;

        return (
            <>
                <MetaTags
                    path={path}
                    title={content.metaTitle}
                    description={content.metaDescription || content.preview}
                    openGraphImage={[ogImageUrl, ogImageThumbnail.fileName]}
                />
                <HtmlGoogleStructuredData
                    data={{
                        type: "News",
                        title: content.title,
                        description: content.preview,
                        imageUrl: ogImageUrl,
                        pageUrl: `${DOMAIN_URL}/news/${news.id}`,
                        authorName: news.copyright.author || news.copyright.url,
                        authorType: !news.copyright.url.includes(COPYRIGHT_LINK)
                            ? "Person"
                            : "Organization",
                        dateModified: news.updatedAt,
                        datePublished: news.date,
                    }}
                />
                <div className={container.flex800}>
                    <Link to={`/news`} className={breadCrumbs}>
                        <Icon className={angle} path={ANGLE_LEFT} />
                        {backToNewsFeed}
                    </Link>

                    <h1 className={newsTitle}>{content.title}</h1>
                    <div className={newsDate}>
                        {formatDate(news.date, "en")}
                    </div>

                    <ImageItem
                        image={news.image}
                        srcSetSizes={{
                            desktop: 770,
                            laptop: 770,
                            tablet: 538,
                        }}
                    />
                    <div className={imageСaption}>
                        {`${imageCredit} `}

                        {mediaItemContent.copyrights.map(({ author, url }) => (
                            <a className={newsAuthor} key={author} href={url}>
                                {author}
                            </a>
                        ))}
                    </div>

                    <ShareButtons
                        pagePath={path}
                        pageTitle={news.content.title}
                        position="top"
                        typename="CompanyNews"
                        medium={news.id}
                        engagementInfo={news.engagementInfo}
                    />

                    <MarkdownRenderer
                        source={content.text}
                        className={markdown}
                    />
                    <div className={credit}>
                        {textCredit}
                        <a
                            className={creditLink}
                            href={news.copyright.url}
                            rel="author"
                        >
                            {news.copyright.author || news.copyright.url}
                        </a>
                    </div>
                    <ShareButtons
                        pagePath={path}
                        pageTitle={news.content.title}
                        position="bottom"
                        typename="CompanyNews"
                        medium={news.id}
                        engagementInfo={news.engagementInfo}
                    />
                </div>
            </>
        );
    }
    return <NotFoundErrorMsg />;
};

export default News;
