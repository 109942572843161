import React from "react";
import { useQuery } from "@apollo/client/react/hooks/useQuery.js";

import { container } from "../styles/common.css.js";
import About from "./About.js";
import Apps from "./Apps.js";
import Intro from "./Intro.js";
import News from "./News.js";
import Partners from "./Partners.js";
import ErrorMsg from "../common/errors/ErrorMsg.js";
import Spinner from "../Spinner.js";
import {
    GetHomeContentData,
    GetHomeContentVariables,
    GET_HOME_CONTENT,
} from "../../graphql/queries/home.js";
import MetaTags from "../MetaTags.js";
import { OPEN_GRAPH_HOME_IMAGE_PATH } from "../../config/content.js";
import { COMPANY_NAME } from "../../../_common/config.js";
import { makeBackgroundImgUrl } from "../../helpers/utils.js";
import NotFoundErrorMsg from "../common/errors/NotFoundErrorMsg.js";

const first = 3;
const Home: React.FC = () => {
    const { data, loading, error } = useQuery<
        GetHomeContentData,
        GetHomeContentVariables
    >(GET_HOME_CONTENT, {
        variables: {
            first: first,
        },
    });

    if (loading) {
        return <Spinner />;
    }

    if (error) {
        return <ErrorMsg error={error} />;
    }
    if (data && data.homePage) {
        const { companyNewsFeed, app } = data;
        const { featuredProducts } = data;
        const {
            content: {
                title,
                metaTitle,
                metaDescription,
                description,
                aboutUsBtn,
                latestNewsTitle,
                moreNewsButton,
                partnersSecTitle,
                allAppsBtn,
                allAppsReadMoreBtn,
                allAppsTitle,
                downloadAppBtn,
                downloadAppBtnLink,
            },
            mediaItems,
        } = data.homePage;
        const introBackgroundImage = mediaItems
            ? makeBackgroundImgUrl(mediaItems[0])
            : null;

        const slides = featuredProducts.map(item => {
            return {
                productId: item.id,
                id: item.homeSliderImage.id,
                image: item.homeSliderImage,
            };
        });

        return (
            <>
                <MetaTags
                    path={""}
                    isHome={true}
                    title={
                        metaTitle ||
                        title.replace(/%COMPANY_NAME%/, COMPANY_NAME)
                    }
                    description={metaDescription || description}
                    openGraphImage={[OPEN_GRAPH_HOME_IMAGE_PATH, "1200x630"]}
                />
                <Intro
                    backgroundImage={introBackgroundImage}
                    title={title}
                    buttonText={downloadAppBtn}
                    buttonUrl={downloadAppBtnLink}
                    icon={
                        app && app.promoApps.length !== 0
                            ? app.promoApps[0].icon
                            : undefined
                    }
                />
                <div className={container.flex}>
                    <About description={description} aboutButton={aboutUsBtn} />
                    {slides && slides.length !== 0 && (
                        <Apps
                            title={allAppsTitle}
                            readMoreButton={allAppsReadMoreBtn}
                            allAppsButton={allAppsBtn}
                            slides={slides}
                        />
                    )}
                    <News
                        news={companyNewsFeed}
                        title={latestNewsTitle}
                        moreNewsButton={moreNewsButton}
                    />
                    {data.partners && (
                        <Partners
                            title={partnersSecTitle}
                            partners={data.partners}
                        />
                    )}
                </div>
            </>
        );
    }
    return <NotFoundErrorMsg />;
};

export default Home;
