import { useQuery } from "@apollo/client/react/hooks/useQuery.js";
import React, { useContext } from "react";

import { OPEN_GRAPH_IMAGE_PATH } from "../../config/content.js";
import {
    GetNewsFeedData,
    GetNewsFeedVariables,
    GET_NEWS_FEED,
} from "../../graphql/queries/news.js";
import { formatDate } from "../../helpers/utils.js";
import Content from "../../i18n/content.js";
import LoadMoreButton from "../common/LoadMoreButton.js";
import ErrorMsg from "../common/errors/ErrorMsg.js";
import { commonPageTitle, container } from "../styles/common.css.js";
import NewsItem from "../home/NewsItem.js";
import MetaTags from "../MetaTags.js";
import Spinner from "../Spinner.js";
import ConfigContext from "../../config/config-context.js";
import { itemsContainer } from "./NewsFeed.css.js";
import NotFoundErrorMsg from "../common/errors/NotFoundErrorMsg.js";

interface Props {
    path: string;
}

const NewsFeed: React.FC<Props> = ({ path }) => {
    const {
        newsFeedPage: { metaTitle, metaDescription, title, loadMoreButton },
    } = useContext(Content);
    const { newsFeedPageItemsCount } = useContext(ConfigContext);

    const { data, loading, error, fetchMore } = useQuery<
        GetNewsFeedData,
        GetNewsFeedVariables
    >(GET_NEWS_FEED, {
        variables: {
            first: newsFeedPageItemsCount,
        },
    });

    if (loading) {
        return <Spinner />;
    }

    if (error) {
        return <ErrorMsg error={error} />;
    }

    if (data && data.companyNewsFeed) {
        const { companyNewsFeed } = data;

        const handleLoadMore = () => {
            fetchMore({
                variables: {
                    first: newsFeedPageItemsCount,
                    after: companyNewsFeed.edges[
                        companyNewsFeed.edges.length - 1
                    ].cursor,
                },
            });
        };

        const { totalCount, offset, edges } = companyNewsFeed;

        const hasMoreNews = totalCount - offset > newsFeedPageItemsCount;

        return (
            <>
                <MetaTags
                    path={path}
                    title={metaTitle || title}
                    description={metaDescription}
                    openGraphImage={[OPEN_GRAPH_IMAGE_PATH, "1200x630"]}
                />
                <div className={container.flex}>
                    <h1 className={commonPageTitle}>{title}</h1>
                    <div className={itemsContainer}>
                        {edges.map(
                            ({
                                node: {
                                    id,
                                    image,
                                    content: { title },
                                    date,
                                },
                            }) => (
                                <NewsItem
                                    key={id}
                                    image={image}
                                    date={formatDate(date, "en")}
                                    title={title}
                                    link={`/news/${id}`}
                                    srcSetSizes={{
                                        desktop: 368,
                                        laptop: 268,
                                        tablet: 536,
                                    }}
                                    isNewsFeed={true}
                                />
                            ),
                        )}
                    </div>
                    {hasMoreNews && (
                        <LoadMoreButton
                            loading={loading}
                            handleLoadMore={handleLoadMore}
                            buttonText={loadMoreButton}
                        />
                    )}
                </div>
            </>
        );
    }

    return <NotFoundErrorMsg />;
};

export default NewsFeed;
