import { useQuery } from "@apollo/client/react/hooks/useQuery.js";
import React, { useContext } from "react";

import { OPEN_GRAPH_IMAGE_PATH } from "../../config/content.js";
import {
    GetProductsFeedData,
    GET_PRODUCTS,
} from "../../graphql/queries/products.js";
import Content from "../../i18n/content.js";
import ErrorMsg from "../common/errors/ErrorMsg.js";
import {
    commonPageSubtitle,
    commonPageTitle,
    container,
} from "../styles/common.css.js";
import MetaTags from "../MetaTags.js";
import Spinner from "../Spinner.js";
import ProductCard from "./ProductCard.js";
import { productsWrapper } from "./Products.css.js";
import NotFoundErrorMsg from "../common/errors/NotFoundErrorMsg.js";
interface Props {
    path: string;
}

const Products: React.FC<Props> = ({ path }) => {
    const { data, loading, error } =
        useQuery<GetProductsFeedData>(GET_PRODUCTS);

    const {
        productsPage: {
            metaTitle,
            metaDescription,
            title,
            subtitle,
            appButton,
        },
    } = useContext(Content);

    if (loading) {
        return <Spinner />;
    }

    if (error) {
        return <ErrorMsg error={error} />;
    }
    if (data && data.products) {
        const { products } = data;
        const { edges } = products;
        return (
            <>
                <MetaTags
                    path={path}
                    title={metaTitle || title}
                    description={metaDescription}
                    openGraphImage={[OPEN_GRAPH_IMAGE_PATH, "1200x630"]}
                />
                <div className={container.flex}>
                    <h1 className={commonPageTitle}>{title}</h1>
                    <p className={commonPageSubtitle}>{subtitle}</p>
                    <div className={productsWrapper}>
                        {edges.map(
                            ({
                                node: {
                                    id,
                                    featured,
                                    productCardImage,
                                    content: { name, preview },
                                },
                            }) => (
                                <ProductCard
                                    key={id}
                                    featured={featured}
                                    image={productCardImage}
                                    title={name}
                                    description={preview}
                                    appButton={appButton}
                                    url={`/apps/${id}`}
                                />
                            ),
                        )}
                    </div>
                </div>
            </>
        );
    }
    return <NotFoundErrorMsg />;
};

export default Products;
