import { useQuery } from "@apollo/client/react/hooks/useQuery.js";
import React, { useContext, useState } from "react";
import { useParams } from "react-router";

import {
    GetProductForSupportPageData,
    GET_PRODUCT_FOR_SUPPORT_PAGE,
} from "../../graphql/queries/products.js";
import ErrorMsg from "../common/errors/ErrorMsg.js";
import Spinner from "../Spinner.js";
import AccordionItem from "./AccordionItem.js";
import SupportForm from "./SupportForm.js";
import Content from "../../i18n/content.js";
import MetaTags from "../MetaTags.js";
import {
    findClosestThumbnail,
    makeBackgroundImgUrl,
} from "../../helpers/utils.js";
import { Thumbnail } from "../../graphql/queries/gallery.js";
import HtmlGoogleStructuredData from "../helmet/HtmlGoogleStructuredData.js";
import {
    anchorButton,
    commonSectionTitle,
    container,
    productIntroTitle,
} from "../styles/common.css.js";
import {
    accordionSubtitle,
    contactBox,
    contactsTitle,
    emptyFaqNotice,
    faqBox,
    intro,
} from "./ProductSupport.css.js";
import { theme } from "../styles/theme.css.js";
import NotFoundErrorMsg from "../common/errors/NotFoundErrorMsg.js";

const ProductSupport: React.FC = () => {
    const { productId } = useParams<{ productId: string }>();

    const [isFormOpened, setIsFormOpened] = useState<boolean>(false);

    const onClose = () => {
        setIsFormOpened(false);
    };
    const {
        productSupportPage: {
            title,
            metaDescription,
            faqTitle,
            faqSubtitle,
            emptyFaqMsg,
            contactTitle,
            contactBtn,
        },
    } = useContext(Content);

    const { loading, error, data } = useQuery<GetProductForSupportPageData>(
        GET_PRODUCT_FOR_SUPPORT_PAGE,
        {
            variables: {
                id: productId,
            },
        },
    );
    if (loading) {
        return <Spinner />;
    }

    if (error) {
        return <ErrorMsg error={error} />;
    }
    if (data && data.product) {
        const { content, introBackgroundImage, productCardImage } =
            data.product;

        const ogImageThumbnail: Thumbnail = findClosestThumbnail(
            productCardImage.content.thumbnails,
        );
        const ogImageUrl =
            productCardImage.content.sourceUrl + ogImageThumbnail.fileName;

        const introBg = makeBackgroundImgUrl(introBackgroundImage);
        const newTitle = title.replace("%{PRODUCTNAME}", content.name);
        return (
            <>
                <MetaTags
                    path={`/support/${productId}`}
                    title={newTitle}
                    description={metaDescription.replace(
                        "%{PRODUCTNAME}",
                        content.name,
                    )}
                    openGraphImage={[ogImageUrl, ogImageThumbnail.fileName]}
                />

                {content.faq ? (
                    <HtmlGoogleStructuredData
                        data={{
                            type: "FAQ",
                            faq: content.faq,
                        }}
                    />
                ) : null}

                <div
                    className={intro}
                    style={{
                        background: `url(${introBg}) center /cover no-repeat ${theme.colors.placeholder} `,
                    }}
                >
                    <h1 className={productIntroTitle}>{newTitle}</h1>
                </div>
                <div className={container.flex800}>
                    {content.faq && (
                        <div className={faqBox}>
                            <h2 className={commonSectionTitle}>{faqTitle}</h2>
                            <h3 className={accordionSubtitle}>{faqSubtitle}</h3>

                            {content.faq.map(item => (
                                <AccordionItem
                                    key={item.question}
                                    q={item.question}
                                    a={item.answer}
                                />
                            ))}
                        </div>
                    )}

                    {!isFormOpened ? (
                        <div className={contactBox}>
                            {content.faq ? (
                                <h3 className={contactsTitle}>
                                    {contactTitle}
                                </h3>
                            ) : (
                                <p className={emptyFaqNotice}>{emptyFaqMsg}</p>
                            )}

                            <button
                                onClick={() => {
                                    setIsFormOpened(true);
                                }}
                                className={anchorButton}
                            >
                                {contactBtn}
                            </button>
                        </div>
                    ) : null}

                    {isFormOpened ? (
                        <SupportForm onClose={onClose} productId={productId} />
                    ) : null}
                </div>
            </>
        );
    }
    return <NotFoundErrorMsg />;
};

export default ProductSupport;
