import React from "react";
import { useLocation } from "react-router";

import { COMPANY_NAME } from "../../../_common/config.js";
import { makeIconUrl } from "../../helpers/utils.js";
import { AppIcon } from "../../graphql/queries/app.js";
import { gaConversionLinkClick } from "../../helpers/googleAnalytics.js";
import { introTitle, introWrapper } from "./Intro.css.js";
import {
    buttonContainer,
    downloadAppButton,
    downloadAppIcon,
} from "../styles/common.css.js";
import { theme } from "../styles/theme.css.js";

interface Props {
    backgroundImage?: string | null;
    title: string;
    buttonText: string;
    buttonUrl: string;
    icon: AppIcon | null | undefined;
}
const Intro: React.FC<Props> = ({
    title,
    buttonText,
    buttonUrl,
    icon,
    backgroundImage,
}) => {
    const partTitle = title.split("%")[0];

    const iconUrl = icon ? makeIconUrl(icon.sourceUrl, icon.sizes) : null;
    const { pathname } = useLocation();

    return (
        <section
            className={introWrapper}
            style={{
                background: `url(${backgroundImage}) center /cover no-repeat ${theme.colors.placeholder}`,
            }}
        >
            <h1 className={introTitle}>
                {partTitle}
                <span>{COMPANY_NAME}</span>
            </h1>
            <div className={buttonContainer}>
                <a
                    href={buttonUrl}
                    onClick={() =>
                        gaConversionLinkClick(
                            "intro_button",
                            pathname,
                            buttonUrl,
                            "home_page",
                        )
                    }
                    className={downloadAppButton}
                >
                    <img
                        src={iconUrl || undefined}
                        className={downloadAppIcon}
                    />
                    {buttonText}
                </a>
            </div>
        </section>
    );
};

export default Intro;
