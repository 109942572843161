import { useQuery } from "@apollo/client/react/hooks/useQuery.js";
import React from "react";

import { OPEN_GRAPH_IMAGE_PATH } from "../../config/content.js";
import { COMPANY_NAME } from "../../../_common/config.js";
import {
    GetAboutContentData,
    GetStaticContentVariables,
    GET_ABOUT_CONTENT,
} from "../../graphql/queries/about.js";
import MarkdownRenderer from "../common/MarkdownRenderer.js";
import ErrorMsg from "../common/errors/ErrorMsg.js";
import MetaTags from "../MetaTags.js";
import { container } from "../styles/common.css.js";
import Spinner from "../Spinner.js";
import { makeBackgroundImgUrl } from "../../helpers/utils.js";
import {
    companyName,
    aboutText,
    introBackground,
    introSubtitle,
    introTitle,
    titleWrapper,
} from "./About.css.js";
import NotFoundErrorMsg from "../common/errors/NotFoundErrorMsg.js";
import { Image } from "../../graphql/queries/gallery.js";

interface Props {
    path: string;
}
interface ContentStructure {
    title: string;
    subtitle: string;
    metaTitle: string;
    metaDescription: string;
    _text: string;
    _history: string;
}

const About: React.FC<Props> = ({ path }) => {
    const { data, loading, error } = useQuery<
        GetAboutContentData<ContentStructure>,
        GetStaticContentVariables
    >(GET_ABOUT_CONTENT);
    if (error) {
        return <ErrorMsg error={error} />;
    }

    if (loading) {
        return <Spinner />;
    }

    if (data && data.staticContent) {
        const {
            content: { title, subtitle, _text, metaTitle, metaDescription },
            mediaItems,
            textImages,
        } = data.staticContent;
        const introBackgroundImage = mediaItems
            ? makeBackgroundImgUrl(mediaItems[0])
            : null;

        const titlePart = title.split(`${COMPANY_NAME}`)[0];

        return (
            <>
                <MetaTags
                    path={path}
                    title={metaTitle || title}
                    description={metaDescription}
                    openGraphImage={[OPEN_GRAPH_IMAGE_PATH, "1200x630"]}
                />
                <div
                    style={{
                        backgroundImage: `url(${introBackgroundImage})`,
                    }}
                    className={introBackground}
                >
                    <div className={titleWrapper}>
                        <h1 className={introTitle}>
                            {titlePart}
                            <span className={companyName}>{COMPANY_NAME}</span>
                        </h1>
                        <p className={introSubtitle}>{subtitle}</p>
                    </div>
                </div>
                <div className={container.flex800}>
                    <MarkdownRenderer
                        source={_text}
                        className={aboutText}
                        images={textImages?.filter((m): m is Image => !!m)}
                    />
                </div>
            </>
        );
    }
    return <NotFoundErrorMsg />;
};

export default About;
