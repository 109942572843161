import React from "react";
import { theme } from "../styles/theme.css.js";
import { appName, getAppButton, textButton } from "./SkyTonightButton.css.js";

interface Prop {
    href: string;
    getAppButtonText: string;
    title: string;
    onClick: () => void;
    backgroundImage?: string | null;
}

const SkyTonightButton: React.FC<Prop> = ({
    href,
    onClick,
    getAppButtonText,
    title,
    backgroundImage,
}) => {
    return (
        <a
            id="get-app-button"
            href={href}
            onClick={onClick}
            style={{
                background: `center / cover no-repeat url(${backgroundImage}) ${theme.colors.link}`,
            }}
            className={getAppButton}
        >
            <span className={appName} id="get-app-button-app-name">
                {title}
            </span>
            <span id="get-app-button-text" className={textButton}>
                {getAppButtonText}
            </span>
        </a>
    );
};

export default SkyTonightButton;
