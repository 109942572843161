import React, { useContext } from "react";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { Link, useLocation } from "react-router-dom";
import rehypeRaw from "rehype-raw";
import rehypeAttrs from "rehype-attr";

import ConfigContext from "../../config/config-context.js";
import {
    gaConversionLinkClick,
    gaMarkdownLinkClick,
} from "../../helpers/googleAnalytics.js";
import { markdownDefaultStyle } from "./MarkdownRenderer.css.js";
import ImageItem from "./ImageItem.js";
import { Image } from "../../graphql/queries/gallery.js";

interface MarkdownLinkProps {
    href?: string;
    children?: React.ReactNode;
}

const MarkdownLink: React.FC<MarkdownLinkProps> = ({ href, children }) => {
    const _href: string = href || "";
    const { conversionDomains } = useContext(ConfigContext);
    const { pathname } = useLocation();
    if (_href.includes("#")) {
        return (
            <a
                href={_href}
                onClick={() => {
                    gaMarkdownLinkClick("hash", _href, pathname);
                }}
            >
                {children}
            </a>
        );
    }

    let path = "";
    try {
        const { hostname, pathname } = new URL(_href);
        if (hostname !== "vitotechnology.com") {
            return (
                <a
                    href={_href}
                    onClick={() => {
                        conversionDomains.includes(hostname)
                            ? gaConversionLinkClick(
                                  "in_article_conversion_link",
                                  pathname,
                                  _href,
                              )
                            : gaMarkdownLinkClick("external", _href, pathname);
                    }}
                >
                    {children}
                </a>
            );
        }
        path = pathname;
    } catch {
        path = _href;
    }
    const to = path;

    // TODO: check links with search params from markdown source
    return (
        <Link
            to={to}
            onClick={() => {
                gaMarkdownLinkClick("internal", to, pathname);
            }}
        >
            {children}
        </Link>
    );
};

interface Props {
    source: string;
    className?: string;
    images?: Image[];
}

const makeImg =
    (images: Image[]): React.FC<{ src?: string }> =>
    ({ src }) => {
        const image = images.find(i => i.id === src);
        if (!image) return null;
        return (
            <ImageItem
                noAspectWrapper
                image={image}
                srcSetSizes={{
                    laptop: 968,
                    tablet: 736,
                }}
            />
        );
    };
const MarkdownRenderer: React.FC<Props> = ({ source, className, images }) => {
    const classNames = [markdownDefaultStyle];
    if (className) classNames.push(className);

    return (
        <Markdown
            className={classNames.join(" ")}
            children={source}
            skipHtml={true}
            components={{
                link: MarkdownLink,
                img: images ? makeImg(images) : undefined,
            }}
            remarkPlugins={[remarkGfm]}
            rehypePlugins={[[rehypeRaw], [rehypeAttrs, { properties: "attr" }]]}
        />
    );
};
export default MarkdownRenderer;
