import React from "react";
import { Helmet } from "react-helmet";
import { COMPANY_NAME } from "../../../_common/config.js";
import { theme } from "../styles/theme.css.js";

const HtmlInit: React.FC = () => {
    return (
        <Helmet>
            <meta charSet="utf-8" />
            <meta
                name="viewport"
                content="width=device-width, initial-scale=1, shrink-to-fit=no"
            />
            <meta name="google" content="notranslate" />
            <link
                rel="icon"
                href="/favicon.ico"
                sizes="16х16 32x32 48х48 180х180"
                type="image/x-icon"
            />
            <link rel="icon" href="/favicon.svg" type="image/svg+xml" />
            <link
                rel="icon"
                href="/icon-180.png"
                sizes="180x180"
                type="image/png"
            />
            <link
                rel="icon"
                href="/favicon.png"
                sizes="512x512"
                type="image/png"
            />
            <link
                rel="apple-touch-icon"
                href="/apple-touch-icon.png"
                sizes="180x180"
                type="image/png"
            />
            <link rel="manifest" href="/manifest.json" />
            <meta name="theme-color" content={theme.colors.background} />
            <meta name="apple-mobile-web-app-capable" content="yes" />
            <meta
                name="apple-mobile-web-app-status-bar-style"
                content="default"
            />
            <title>{COMPANY_NAME}</title>
        </Helmet>
    );
};

export default HtmlInit;
