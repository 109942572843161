import React, { useState } from "react";

import { ANGLE_LEFT } from "../../config/icons.js";
import Icon from "../svg/Icon.js";
import {
    answerBlock,
    answerTab,
    arrow,
    openedAnswer,
    questionTab,
    rotatedArrow,
    text,
    wrapper,
} from "./AccordionItem.css.js";

interface Props {
    q: string;
    a: string;
}
const AccordionItem: React.FC<Props> = ({ q, a }) => {
    const [isOpen, setIsOpen] = useState(false);
    const questionClick = () => {
        setIsOpen(prevState => !prevState);
    };
    return (
        <>
            <div className={wrapper}>
                <div
                    className={questionTab}
                    onClick={questionClick}
                    style={{
                        backgroundColor: isOpen ? "#dedede" : "inherit",
                    }}
                >
                    <p className={text}>{q}</p>
                    <Icon
                        className={isOpen ? rotatedArrow : arrow}
                        path={ANGLE_LEFT}
                    />
                </div>
                <div className={isOpen ? openedAnswer : answerTab}>
                    <div className={answerBlock}>{a}</div>
                </div>
            </div>
        </>
    );
};

export default AccordionItem;
