import gql from "graphql-tag";
import { IMediaItem, Image } from "./gallery.js";

export interface GetStaticContentVariables {
    id: string;
}

export interface GetAboutContentData<T> {
    staticContent: {
        id: string;
        content: T;
        mediaItems: (IMediaItem | null)[] | null;
        textImages: (Image | null)[] | null;
    };
}

export const GET_ABOUT_CONTENT = gql`
    query getAboutContent {
        staticContent(id: "vt-site-about") {
            id
            content
            mediaItems {
                id
                content {
                    name
                    sourceUrl
                    ... on ImageContent {
                        thumbnails {
                            fileName
                            width
                            height
                        }
                    }
                }
            }
            textImages {
                id
                content {
                    name
                    sourceUrl
                    ... on ImageContent {
                        thumbnails {
                            fileName
                            width
                            height
                        }
                    }
                }
            }
        }
    }
`;
