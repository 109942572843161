import React, { useContext } from "react";
import { withRouter, RouteComponentProps, useLocation } from "react-router";
import { Link } from "react-router-dom";

import { link, linksWrapper } from "./NotFoundErrorMsg.css.js";
import { gaNavClick } from "../../../helpers/googleAnalytics.js";
import ErrorPage from "./ErrorPage.js";
import Content from "../../../i18n/content.js";

const NotFoundErrorMsg: React.FC<RouteComponentProps> = ({ staticContext }) => {
    const {
        common: {
            home,
            products,
            news,
            aboutUs,
            support,
            contactUs,
            errNotFoundTitle,
            errNotFoundMsg,
        },
    } = useContext(Content);

    const { pathname } = useLocation();

    if (staticContext) {
        staticContext.statusCode = 404;
    }

    const links = [
        {
            key: "home",
            path: "",
            text: home,
        },
        {
            key: "apps",
            path: "/apps",
            text: products,
        },
        {
            key: "news",
            path: "/news",
            text: news,
        },
        {
            key: "about",
            path: "/about",
            text: aboutUs,
        },
        {
            key: "support",
            path: "/support",
            text: support,
        },
        {
            key: "contacts",
            path: "/contacts",
            text: contactUs,
        },
    ];
    return (
        <ErrorPage title={errNotFoundTitle} message={errNotFoundMsg}>
            <div className={linksWrapper}>
                {links.map(({ key, path, text }) => (
                    <Link
                        key={key}
                        to={path}
                        onClick={() => {
                            gaNavClick(key, "page404", pathname);
                        }}
                        className={link}
                    >
                        {text}
                    </Link>
                ))}
            </div>
        </ErrorPage>
    );
};

export default withRouter(NotFoundErrorMsg);
